import styled from "styled-components";
import { BoostLabel } from "../../shared/components/boost-label";
import { BoostKind, UserBoost } from "../../types";

import lightningIconUrl from "../../images/boost-icons/lightning.png";
import priestIconUrl from "../../images/boost-icons/priest.png";
import starsIconUrl from "../../images/boost-icons/stars.png";

const Container = styled.div`
    position: absolute;
    bottom: 80px;
    left: 12px;

    display: flex;
    gap: 8px;
    flex-direction: column;
    z-index: 5;
`

type Props = {
  boosts: UserBoost[],
  nfts: number[]
}

export const Boosts = ({ nfts, boosts }: Props) => {
  const getPopoverText = (boost: UserBoost) => {
    if (boost.kind === BoostKind.Pray) {
      return 'Faster Pray – a boost to the speed of praying.'
    }
    return 'More Faith – a boost to the amount of faith.'
  }

  const getIconUrl = (boost: UserBoost) => {
    if (boost.kind === BoostKind.Pray) {
      return lightningIconUrl
    }
    return starsIconUrl
  }

  return (
    <Container>
      {nfts.length > 0 && (
        <BoostLabel
          popoverText="More Faith – a boost to the amount of faith."
          iconUrl={priestIconUrl}
        >
          x{Math.pow(2, nfts.length)}
        </BoostLabel>
      )}
      {boosts.map((boost, index) => {
        return (
          <BoostLabel
            key={index}
            // inactive={!boost.using && !boost.expiration}
            iconUrl={getIconUrl(boost)}
            popoverText={getPopoverText(boost)}
          >
            x{boost.value}
          </BoostLabel>
        );
      })}
    </Container>
  );
}
